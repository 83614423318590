<template>
  <div class="my-register-page">
    <div class="my-container">
      <div>
        <b-row>
          <b-col>
            <h1>{{ $t("Inspector") }}</h1>
          </b-col>
          <b-col class="mt-4" style="text-align:right">
            <b-button
              style="width:100px"
              @click="$router.go(-1)"
              variant="outline-danger"
            >
              {{ $t("back") }}
            </b-button>
          </b-col>
        </b-row>
        <!-- <b-row class="mt-4">
                  <b-col sm="3">
                    <custom-button @click.native="toggle" variant="success">
                      <div class="mx-4" >
                        <img src="images/school/edit.svg" alt=""> <span> {{ $t('changepassword') }} </span> 
                      </div>
                    </custom-button>
                  </b-col>
                   <b-col sm="9"> </b-col>
                </b-row> -->
        <b-row class="mt-4">
          <b-col sm="12" md="4">
            <!-- <custom-input
              @keyup.native.enter="Refresh"
              v-model="filter.certificateNumber"
              :label="$t('certificatenumber')"
              autoComplete="off"
              :placeholder="$t('certificatenumber')"
            ></custom-input> -->
            <custom-input
              @keyup.native.enter="Refresh"
              v-model="filter.certificateNumber"
              :type="'text'"
              :label="$t('certificateNumberLabel')"
              :placeholder="$t('certificateNumberLabel')"
            />
          </b-col>
          <b-col sm="12" md="3">
            <custom-button block @click.native="Refresh">
              <span>
                <b-spinner
                  style="margin-left : 20px"
                  v-if="SearchLoading"
                  small
                ></b-spinner>
                {{ $t("search") }}
              </span>
            </custom-button>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col
            sm="12"
            md="6"
            lg="4"
            class="my-2"
            v-for="(item, index) in Inspector"
            :key="index"
          >
            <b-row
              class="my-card-border mx-1 pt-3 bg-white d-flex align-items-center"
            >
              <b-col sm="12" class="d-flex" style="padding-left:40px">
                <p class="pt-3">
                  <span class="my-text-primary">
                    {{ $t("certificateNumbeR") }}
                  </span>
                  : <span>{{ item.certificateNumber }}</span>
                </p>
              </b-col>
              <b-col sm="12" class="d-flex" style="padding-left:40px">
                <p>
                  <span class="my-text-primary">
                    {{ $t("expirationDate") }}
                  </span>
                  : <span>{{ item.expirationDate }}</span>
                </p>
              </b-col>
              <b-col sm="12" class="d-flex" style="padding-left:40px">
                <p>
                  <span class="my-text-primary"> {{ $t("fio") }} </span> :
                  <span>{{ item.fullName }}</span>
                </p>
              </b-col>
              <b-col sm="12" class="d-flex" style="padding-left:40px">
                <p>
                  <span class="my-text-primary"> {{ $t("position") }} </span> :
                  <span>{{ item.position }}</span>
                </p>
              </b-col>
              <b-col sm="12" class="d-flex" style="padding-left:40px">
                <p>
                  <span class="my-text-primary">
                    {{ $t("parentOrganization") }}
                  </span>
                  : <span>{{ item.parentOrganization }}</span>
                </p>
              </b-col>
              <b-col sm="12" class="d-flex" style="padding-left:40px">
                <p>
                  <span class="my-text-primary">
                    {{ $t("Organization") }}
                  </span>
                  : <span>{{ item.organization }}</span>
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- <b-row style="margin-top:16px">
                    <b-col sm="12" md="8">
                        <custom-input  disabled   v-model="fio" :label="$t('fio')"></custom-input>
                    </b-col>
                </b-row>
                 <b-row style="margin-top:16px">
                    <b-col sm="12" md="4">
                        <custom-input  disabled v-model="person.birthDate" :label="$t('birthDate')"></custom-input>
                    </b-col>
                    <b-col sm="12" md="4">
                        <custom-input  disabled   v-model="passport" :label="$t('passport')"></custom-input>
                    </b-col>
                    <b-col sm="12" md="4">
                        <custom-input  disabled   v-model="person.pinfl" :label="$t('pinfl')"></custom-input>
                    </b-col>
                </b-row>
                <b-row style="margin-top:16px">
                    <b-col sm="12" md="4">
                        <custom-input  disabled v-model="Inspector.position" :label="$t('position')"></custom-input>
                    </b-col>
                    <b-col sm="12" md="4">
                        <custom-input  disabled   v-model="Inspector.expirationDate" :label="$t('expirationDate')"></custom-input>
                    </b-col>
                    <b-col sm="12" md="4">
                        <custom-input  disabled v-model="Inspector.orderType" :label="$t('orderType')"></custom-input>
                    </b-col>
                </b-row>
                <b-row style="margin-top:16px">
                    <b-col sm="12" md="12">
                        <custom-input  disabled v-model="Inspector.organization" :label="$t('Organization')"></custom-input>
                    </b-col>
                </b-row>
                <b-row style="margin-top:16px">
                    <b-col sm="12" md="12">
                        <custom-textarea  disabled v-model="Inspector.parentOrganization" :label="$t('parentOrganization')"></custom-textarea>
                    </b-col>
                </b-row> -->
      </div>
    </div>
    <b-sidebar v-model="sidebar1" width="400px" shadow no-header right>
      <div class="bg-white h-100 d-flex align-items-center">
        <div>
          <div>
            <span style="font-size: 30px; margin-left: 30px">
              {{ $t("changepassword") }}
            </span>
          </div>
          <div class="mt-4" style="margin-left: 30px;margin-right: 30px">
            <custom-input
              type="password"
              placeholder="******"
              v-model="password.oldpassword"
              :label="$t('oldpassword')"
            ></custom-input>
          </div>
          <div class="mt-2" style="margin-left: 30px;margin-right: 30px">
            <custom-input
              type="password"
              placeholder="******"
              v-model="password.newpassword"
              :label="$t('newpassword')"
            ></custom-input>
          </div>
          <div class="mt-2" style="margin-left: 30px;margin-right: 30px">
            <custom-input
              type="password"
              placeholder="******"
              v-model="password.confirmedpassword"
              :label="$t('confirmedpassword')"
            ></custom-input>
          </div>
          <div class="mt-3" style="margin-left: 30px;margin-right: 30px">
            <custom-button
              variant="success"
              @click.native="changepassword"
              block
            >
              <b-spinner
                v-if="passwordLoading"
                small
                style="margin-right:8px"
              ></b-spinner>
              <div class="mx-4">
                <span> {{ $t("save") }} </span>
              </div>
            </custom-button>
          </div>
        </div>
        <span
          class="close-btn"
          style="position: absolute; top : 0px; right: 0px;cursor:pointer"
          @click="toggle"
        >
          <img src="images/design/fill-close.svg" alt="" />
        </span>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BSpinner,
  BSidebar,
  BFormCheckbox,
  BIconPencil,
  BIconCheck2Square,
  BFormFile,
  BIconPlus,
  BIconX,
} from "bootstrap-vue";
import customInput from "../../components/elements/customInput.vue";
import customTextarea from "../../components/elements/customTextarea.vue";
import customDatePicker from "../../components/elements/customDatePicker.vue";
import customSelect from "../../components/elements/customSelect.vue";
import customButtonOutline from "../../components/elements/customButtonOutline.vue";
import customButton from "../../components/elements/customButton.vue";
import customRadioButton from "../../components/elements/customRadioButton.vue";
import customBadge from "../../components/elements/customBadge.vue";
import customDialog from "../../components/elements/customDiaolg.vue";
import AccountService from "@/services/account.service";
import InspectorService from "@/services/inspector.service";
import customLabel from "../../components/elements/customLabel.vue";
import PlusIcon from "../../components/custom-icons/PlusIcon.vue";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BImg,
    customInput,
    customDatePicker,
    customSelect,
    BIconX,
    customButtonOutline,
    BSpinner,
    customButton,
    PlusIcon,
    customRadioButton,
    vSelect,
    customDialog,
    customLabel,
    BSidebar,
    BFormCheckbox,
    BIconPencil,
    BIconCheck2Square,
    BFormFile,
    BIconPlus,
    customBadge,
    customTextarea,
  },
  data() {
    return {
      OblastList: [],
      SearchLoading: false,
      filter: {
        docseries: "",
        docnumber: "",
        dateofbirth: "",
        identitydocumentid: 2,
        kinshipdegreeid: 0,
        ismicroterritory: false,
        certificateNumber: null,
      },
      files: {
        file: [],
        filename: "",
        url: "",
      },
      Inspector: [],
      IdentityDocumentList: [],
      PermanentAdress: {},
      isPermanentAdress: true,
      isActiveAdress: false,
      RefreshEgovLoading: false,
      SaveLoading: false,
      lang: "",
      sidebar1: false,
      passwordLoading: false,
      password: {
        oldpassword: "",
        newpassword: "",
        confirmedpassword: "",
      },
      iseditmode: false,
      admtype: 0,
      FileLoading: false,
      AttachedFiles: [],
      fio: "",
      passport: "",
      person: {},
    };
  },
  created() {
    this.Refresh();

    this.lang = localStorage.getItem("locale") || "uz_cyrl";
  },
  methods: {
    Refresh() {
      if (this.filter.certificateNumber.length == 0) {
        this.SearchLoading = true;
        InspectorService.Get()
          .then((res) => {
            this.Inspector = res.data;
            this.fio =
              res.data.person.surnameLatin +
              "  " +
              res.data.person.nameLatin +
              "  " +
              res.data.person.patronymLatin;
            this.passport =
              res.data.person.passportSeria +
              " " +
              res.data.person.passportNumber;
            this.person = res.data.person;
            this.SearchLoading = false;
          })
          .catch((error) => {
            this.SearchLoading = false;
            this.makeToast(error.response.data.errors, "error");
            this.Inspector = [];
            this.person = {};
            this.fio = "";
            this.passport = "";
          });
      } else {
        this.SearchLoading = true;
        InspectorService.Get(encodeURIComponent(this.filter.certificateNumber))
          .then((res) => {
            this.Inspector = res.data;
            this.fio =
              res.data.person.surnameLatin +
              "  " +
              res.data.person.nameLatin +
              "  " +
              res.data.person.patronymLatin;
            this.passport =
              res.data.person.passportSeria +
              " " +
              res.data.person.passportNumber;
            this.person = res.data.person;
            this.SearchLoading = false;
          })
          .catch((error) => {
            this.SearchLoading = false;
            this.makeToast(error.response.data.errors, "error");
            this.Inspector = [];
            this.person = {};
            this.fio = "";
            this.passport = "";
          });
      }
      // if(!!this.filter.certificateNumber){
      //   this.SearchLoading = true
      //   InspectorService.Get(encodeURIComponent(this.filter.certificateNumber)).then(res => {
      //       this.Inspector = res.data
      //       this.fio = res.data.person.surnameLatin + '  ' + res.data.person.nameLatin + "  " + res.data.person.patronymLatin
      //       this.passport = res.data.person.passportSeria + ' ' + res.data.person.passportNumber
      //       this.person = res.data.person
      //       this.SearchLoading = false
      //   }).catch(error => {
      //       this.SearchLoading = false
      //       this.makeToast(error.response.data.errors,'error')
      //       this.Inspector =[]
      //       this.person={}
      //       this.fio=''
      //       this.passport=''

      //   })
      // }
    },
    toggle() {
      this.sidebar1 = !this.sidebar1;
      this.password = {
        oldpassword: "",
        newpassword: "",
        confirmedpassword: "",
      };
    },
    changepassword() {
      this.passwordLoading = true;
      AccountService.ChangePassword(this.password)
        .then((res) => {
          this.passwordLoading = false;
          this.makeToast(this.$t("PasswordChangedSuccess"), "success");
        })
        .catch((error) => {
          this.passwordLoading = false;
          this.makeToast(error.response.data.error, "error");
        });
    },
    ChangeStreetHouse() {
      if (!!this.Parent.livestreethousename) {
        this.Parent.ismultistoreyhouse = this.StreetHouseList.filter(
          (item) => item.streethousename === this.Parent.livestreethousename
        )[0].ismultistoreyhouse;
      }
    },
    makeToast(message, type) {
      var a = "";
      if (message.status == 500) {
        a = message.title;
      }
      if (message.status == 400) {
        var errors = Object.values(message.errors);
        var a = errors.map((el, item) => item + 1 + "." + el).join("\n");
      } else {
        a = message;
      }
      this.$toast.open({
        message: a,
        type: type,
        duration: 5000,
        dismissible: true,
      });
    },
  },
};
</script>

<style></style>
